@font-face {
  font-family: "Roboto-Reg";
  src: url("../../theme/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Exo";
  src: url("../../theme/fonts/Exo-Bold.ttf");
}

@font-face {
  font-family: "Exo-Light";
  src: url("../../theme/fonts/Exo-Light.ttf");
}

body,
html {
  overflow: hidden;
}

.font-exo {
  font-family: "Exo", sans-serif !important;
}

.font-exo-light {
  font-family: "Exo-Light", sans-serif !important;
}

/* Buttons Custom */

.button-custom:hover,
.button-custom:active,
.button-custom {
  outline: none !important;
  box-shadow: none !important;
}

.button-card {
  margin-top: 1rem !important;
  margin-bottom: -3rem !important;
  z-index: 9999;
}

.button-menu-user,
.button-menu-user:hover {
  color: var(--gray-80) !important;
  background-color: transparent !important;
}

/* Badge */

.p-overlay-badge .p-badge {
  top: 1px !important;
  right: 4px !important;
}

/* Carousel */

.p-carousel-container {
  position: sticky;
}

.p-carousel-content {
  flex-direction: column-reverse !important;
}

.p-carousel .p-carousel-content .p-carousel-prev {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
  position: absolute;
  top: -22px;
  right: 22px;
  color: var(--secondary-color);
}

.p-carousel .p-carousel-content .p-carousel-next {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
  position: absolute;
  top: -22px;
  right: 0;
  color: var(--secondary-color);
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: var(--secondary-color);
  border-color: transparent;
  background: var(--secondary-color-light);
}

.p-carousel .p-carousel-content .p-carousel-prev.p-disabled,
.p-carousel .p-carousel-content .p-carousel-next.p-disabled {
  color: var(--gray-20);
}

.p-carousel-prev-icon,
.p-carousel-next-icon {
  font-size: 0.7rem;
}

.p-carousel .p-carousel-indicators {
  padding: 1.2rem 0 0 0;
  justify-content: flex-start;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* Charts */

.apexcharts-text.apexcharts-datalabel-value {
  font-size: 14px !important;
  font-family: "Roboto-Reg" !important;
  fill: var(--gray-50) !important;
}

/* Sidebar */

.menu-item {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  color: rgba(0, 0, 0, 0.8);
}

.menu-item.active:hover,
.menu-item.active {
  color: var(--secondary-color);
  font-weight: 500;
}

.menu-item.active:hover > span,
.menu-item.active > span {
  color: rgba(0, 0, 0, 0.8);
}
